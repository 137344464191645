<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-alert
                    dense
                    outlined
                    color="error"
                    icon="mdi-alert-circle"
                >
                    Termine Löschen?
                </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    outlined
                    color="secondary"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    @click="onDelete"
                    color="error"
                    class="confirm-delete-btn"
                    depressed
                >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    import { mapMutations } from 'vuex';

    export default {
        props: {
            value: Boolean,
        },
        methods: {
            onDelete() {
                this.$emit('confirm-deletion');
                this.isOpen = false
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
        }
    }
</script>

<style scoped>

</style>